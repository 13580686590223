<script setup lang="ts">
import { NuxtLink } from '#components'

const { breadcrumbs } = useNav()
</script>

<template>
  <nav
    v-if="breadcrumbs.length > 0"
    class="flex items-center gap-2 py-3 text-sm text-icsgray-800 dark:text-icsgray-200"
  >
    <NuxtLink
      class="transition-color @hover:text-icsgray-600 @hover:dark:text-icsgray-50 focusable"
      to="/"
    >
      Home
    </NuxtLink>
    <template
      v-for="crumb, index in breadcrumbs"
      :key="crumb.to"
    >
      <span>/</span>
      <component
        :is="index === breadcrumbs.length - 1 ? 'span' : NuxtLink"
        :to="crumb.to"
        class="capitalize transition-color last:pointer-events-none last:opacity-62 @hover:text-icsgray-600 @hover:dark:text-icsgray-50 focusable"
      >
        {{ crumb.title }}
      </component>
    </template>
  </nav>
</template>
