<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const props = withDefaults(defineProps<{
  theme?: 'light' | 'dark'
  headline?: string
  showHeadline?: boolean
  nav?: NavItem[]
  showNav?: boolean
  showCrumbs?: boolean
  atRoot?: boolean
  bgClass?: string | false
  exactActiveClass?: string
}>(), {
  theme: 'light',
  showHeadline: true,
  showNav: true,
  showCrumbs: true,
  atRoot: true,
})

if (import.meta.client)
  gsap.registerPlugin(ScrollTrigger)

let gsapContext: ReturnType<typeof gsap.context>

const { route, theNav, getNavItems } = useNav()

const parallaxViewEl = ref<HTMLDivElement>()
const parallaxEl = ref<HTMLDivElement>()

const menu = computed(() => {
  if (props.nav)
    return props.nav
  else if (theNav.value?.index[route.matched[0]?.name as string]?.children)
    return getNavItems(theNav.value.index[route.matched[0]!.name as string]!.children!)
  return []
})

const theBackground = computed(() => {
  if (props.bgClass === false) {
    return ''
  }
  else {
    return props.theme === 'light'
      ? 'bg-pattern-diamonds'
      : 'bg-pattern-diamonds-dark'
  }
})

onMounted(() => {
  if (!parallaxEl.value)
    return
  gsap.set(parallaxEl.value, {
    y: 0,
  })
  gsap.to(parallaxEl.value, {
    y: '12vh',
    ease: 'none',
    scrollTrigger: {
      trigger: parallaxViewEl.value,
      start: 'clamp(top bottom)',
      end: 'bottom top',
      scrub: true,
    },
  })
})

onUnmounted(() => {
  if (gsapContext)
    gsapContext.revert()
})
</script>

<template>
  <BlockWindow
    class="min-h-60 shadow-inner transition-height"
    :class="{ ' [&_a]:!heading-link-white text-white [&_span]:text-white': theme === 'dark' }"
  >
    <slot name="heading">
      <BlockContent class="relative z-100">
        <TheBreadcrumbs v-if="showCrumbs" />
        <NuxtLink
          v-if="showHeadline"
          :to="$route.matched[0]?.path"
        >
          <component
            :is="atRoot ? 'h1' : 'div'"
            class="inline-block page-title"
          >
            {{ headline || $route.matched[0]?.meta.title || $route.meta.title }}
          </component>
        </NuxtLink>
        <ANav
          v-if="showNav && menu && menu.length > 0"
          v-bind="$props"
          :menu
          size="large"
          inline
          class="my-4 drop-shadow"
        />
      </BlockContent>
    </slot>
    <slot name="default" />
    <div
      ref="parallaxViewEl"
      class="absolute-inset -z-1"
    >
      <div
        ref="parallaxEl"
        class="absolute bottom-0 min-h-125% w-full"
      >
        <slot name="parallax" />
      </div>
    </div>
    <div
      class="absolute-inset -z-1000"
      :class="bgClass ? bgClass : theBackground"
    />
  </BlockWindow>
</template>
